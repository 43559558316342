<template>
  <b-row class="match-height">
    <b-col lg="8">
      <ListJobOpenings :data-list="jobOpeningsList" />
    </b-col>
    <b-col lg="4">
      <Sidebar />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import getDataList from '@/firestore/data/getList'
import ListJobOpenings from '@/components/company/jobOpenings/ListJobOpenings.vue'
import Sidebar from '@/components/company/sidebar/test/Sidebar.vue'

export default {
  components: {
    BRow,
    BCol,
    ListJobOpenings,
    Sidebar,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('jobApplicantBasic', ['jobOpeningsList']),
  },
  async mounted() {
    const data = {
      collection: 'jobOpenings',
    }
    const response = await getDataList(data)
    if (response.status === 'success') {
      this.$store.dispatch('jobApplicantBasic/updateList', response.data)
    } else {
      window.console.log('error', response.msg)
    }
  },
}
</script>

<style lang="scss">
</style>
