<template>
  <div>
    <h2>求人情報一覧</h2>
    <b-card
      title=""
    >
      <div
        v-for="(item,index) in dataList"
        :key="index"
        :class="index ? 'mt-2':''"
      >
        <b-row>
          <b-col md="6">
            <h5
              class="text-capitalize mb-75"
            >
              [ {{ index + 1 }} ]
              <span v-if="item.recruitmentType !== void 0">{{ item.recruitmentType }}</span>
            </h5>
          </b-col>
          <!-- submit and reset -->
          <b-col md="6">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              size="sm"
              variant="flat-primary"
              @click="detail(item.docID)"
            >
              詳細
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-row>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            class="mt-3"
            @click="add"
          >
            新規作成
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    dataList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    }
  },
  methods: {
    detail(id) {
      this.$router.push({ path: '/job-jobapplicant', query: { id } })
    },
    add() {
      this.$router.push({ path: '/job-jobapplicant', query: { met: 'create' } })
    },
  },
}
</script>
